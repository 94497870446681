import type { ICategory } from "@magnit/unit-catalog/src/unit-catalog.types";
import { urls } from "~/api/config";
import type { IGoodCategoryDataResponse, IGoodCategoryResponse } from "~/typings/api";
import { API_SHOP_TYPE } from "~/constants/api";

export default () => {
  const storesStore = useStoresStore();
  const { storeShopCode } = storeToRefs(storesStore);

  const requestGoodsCategories = async () => {
    const formatGoodsCategories = (
      category: IGoodCategoryResponse,
      parentKey: ICategory["parentKey"] = null,
    ): ICategory => {
      const key = `g${category.id}`;
      return {
        key,
        parentKey,
        id: String(category.id),
        name: category.name,
        code: category.seoCode,
        icon:
          category.image && parentKey === GOODS_ROOT_KEY
            ? category.image
            : undefined,
        url: `${Routes.Catalog}/${category.id}-${category.seoCode}?shopCode=${storeShopCode.value}&shopType=${API_SHOP_TYPE}`,
        children: category.children.map((subcategory) =>
          formatGoodsCategories(subcategory, key),
        ),
      };
    };

    const transform = (data: IGoodCategoryResponse[]): ICategory[] =>
      data
        .filter((category) => !GOODS_IGNORE_IDS.includes(category.id))
        .map((category) => formatGoodsCategories(category, GOODS_ROOT_KEY));
    const { data } = await useTransport<IGoodCategoryDataResponse>(
      `${urls.goods.categories}/${storeShopCode.value}`,
      {
        query: {
          storetype: API_SHOP_TYPE,
          catalogtype: API_CATALOG_TYPE,
        },
      },
    );
    return transform(data.value?.items ?? []);
  };

  return {
    requestGoodsCategories,
  };
};
